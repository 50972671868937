import React from 'react'
import './Projects.css'
const ProjectCard = ({projectImg,ProjectDecription,ProjectPreview,ProjectCodeLink}) => {
  return (
    <>
    
    
      <div className="col-md-4" >
        <div className="cards">
        <img className='card-img' src={projectImg} alt="" />
        <p>{ProjectDecription}</p>

        <a href={ProjectPreview} target='_blank'><button className='card-btn1'>Preview</button></a>
        <a href={ProjectCodeLink} target='_blank'><button className='card-btn2'  >Code</button></a>
        
        </div>
        </div>


    </>
  )
}

export default ProjectCard