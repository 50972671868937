import React from 'react'
import './Projects.css'
import moviflix from '../Images/moviflix.PNG'
import services from '../Images/IT-services.PNG'
import leatherfaux from '../Images/leatherfaux.PNG'
import sportblog from '../Images/sportblog.PNG'
import protiengrind from '../Images/protiengrind.PNG'
import ProjectCard from './ProjectCard'
import oracions from '../Images/oracions.PNG'
import Aura from '../Images/aura.PNG'
import myblogvista from '../Images/myblogvista.PNG'
import infogalm from '../Images/infogalm.PNG'

const Projects = () => {
  return (
    <>
    <div className="container" id='project' style={{marginTop:"150px"}}>
      <h1 style={{ marginBottom:"25px"}}>My Recent Projects</h1>
      <p>Some of the projects are not included due to privacy issues and also some projects code links are not available for better privacy</p>
      <div className="row" >

      <ProjectCard
       projectImg={infogalm}
       ProjectDecription={"InfoGalm is a blogging site developed with the wordpress for posting articles about fashion and beauty"}
       ProjectPreview={"https://infogalm.com/"}
       ProjectCodeLink={""}
      />

      <ProjectCard
       projectImg={myblogvista}
       ProjectDecription={"MyBlogVista is a blogging site developed with the wordpress for posting articles about Tech, Auto etc."}
       ProjectPreview={"https://myblogvista.com/"}
       ProjectCodeLink={""}
      />

       <ProjectCard
       projectImg={Aura}
       ProjectDecription={"Aura is an admin dashboard developed in react js to calculate the orders, progress and analytics of users"}
       ProjectPreview={"https://auradashboard.netlify.app/"}
       ProjectCodeLink={""}
      />

      <ProjectCard
       projectImg={oracions}
       ProjectDecription={"Oracions is an IT company.It's a hub of technological expertise"}
       ProjectPreview={"https://oracions.com/"}
       ProjectCodeLink={""}
      />

      <ProjectCard
       projectImg={moviflix}
       ProjectDecription={"This is Netflix clone website in react js in which i use API's for data and Firebase as a backend."}
       ProjectPreview={"https://khalilnetflix.netlify.app/"}
       ProjectCodeLink={"https://github.com/khalilkhan-Dev/Netflix-Clone"}
       />

       <ProjectCard
       projectImg={services}
       ProjectDecription={"This is IT-services providing website in Bootstrap and AOS librabry for animation."}
       ProjectPreview={"https://khalilkhan-dev.github.io/IT-Services/"}
       ProjectCodeLink={"https://github.com/khalilkhan-Dev/IT-Services"}
       />

       <ProjectCard
       projectImg={leatherfaux}
       ProjectDecription={"Leatherfaux is an e-commerce website which i created using wordpress with customization"}
       ProjectPreview={""}
       ProjectCodeLink={""}
       />

      <ProjectCard
       projectImg={sportblog}
       ProjectDecription={"SportsBlog is an blogging site which i created using wordpress for uploading sports blogs"}
       ProjectPreview={""}
       ProjectCodeLink={""}
      />

      <ProjectCard
       projectImg={protiengrind}
       ProjectDecription={"Protiengrind in an e-commerce store for selling protien powders which i built using html,css,js"}
       ProjectPreview={""}
       ProjectCodeLink={""}
      />
      


      </div>
    </div>
    </>
  )
}

export default Projects